import { Button } from '@/components/ui/button'
import { useOrganization, useOrganizationList, useUser } from '@clerk/nextjs'
import { BarChart, Home } from 'lucide-react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

export const CTAButton = ({
  analyticsKey,
  content,
  destination,
}: {
  destination: string
  content: string | React.ReactNode
  analyticsKey: string
}) => {
  return (
    <Link href={destination}>
      <Button
        variant={'link'}
        analyticsKey={analyticsKey}
        analyticsProperties={{
          section: 'public_site',
        }}
        className="mb-6"
      >
        {content}
      </Button>
    </Link>
  )
}

const BottomNav = () => {
  const { user, isLoaded } = useUser()
  const { organization } = useOrganization()
  const orgList = useOrganizationList({
    userInvitations: { pageSize: 10 },
    userMemberships: { pageSize: 10 },
  })

  const onboarded = user?.publicMetadata.onboarded

  const renderActionButton = () => {
    if (organization?.id) {
      return (
        <>
          <div className="hidden sm:block">
            <CTAButton
              analyticsKey="bottom_nav_go_to_dashboard_with_org"
              content="Dashboard"
              destination="/dashboard"
            />
          </div>
          <div className="sm:hidden">
            <CTAButton
              analyticsKey="bottom_nav_go_to_dashboard_with_org"
              content={
                <Home
                  className={`w-9 h-9 p-2 rounded-lg ${pathname === '/dashboard' ? 'bg-orange-100 text-orange-500' : 'text-zinc-500'}`}
                />
              }
              destination="/dashboard"
            />
          </div>
        </>
      )
    }

    if (user?.publicMetadata.isSubscribed) {
      if (!onboarded) {
        return (
          <CTAButton
            analyticsKey="bottom_nav_go_to_onboarding"
            content="Onboard"
            destination="/onboarding"
          />
        )
      }

      return (
        <CTAButton
          analyticsKey="bottom_nav_go_to_dashboard"
          content="Dashboard"
          destination="/dashboard"
        />
      )
    }

    return (
      <CTAButton analyticsKey="bottom_nav_go_to_pricing" content="Pricing" destination="/pricing" />
    )
  }

  useEffect(() => {
    user?.getOrganizationInvitations()
  }, [isLoaded])

  const pathname = usePathname()

  return (
    <div className="mt-20">
      <div className="fixed z-50 bottom-0 left-0 right-0 bg-white border-t border-zinc-200 py-3 px-4 flex justify-around items-center sm:hidden">
        {renderActionButton()}
        <CTAButton
          analyticsKey="bottom_nav_go_to_dashboard_with_org"
          content={
            <BarChart
              className={`w-9 h-9 p-2 rounded-lg ${pathname === '/dashboard/insights' ? 'bg-orange-100 text-orange-500' : 'text-zinc-500'}`}
            />
          }
          destination="/dashboard/insights"
        />

        {/* <UserButton afterSignOutUrl="/" /> */}

        <div className="w-6" />
      </div>
    </div>
  )
}

export default BottomNav
