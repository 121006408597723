import { Cog, Loader2 } from 'lucide-react'

export const SmallLoader = () => {
  return <Loader2 className="mx-auto h-4 w-4 animate-spin" />
}

export const SmallLoaderNotCentered = () => {
  return <Loader2 className="h-4 w-4 animate-spin" />
}

export const WebsiteCheckLoader = () => {
  return (
    <div className="w-fit rounded-lg flex items-center text-zinc-600">
      <Loader2 className="mx-auto h-4 w-4 animate-spin mr-2" />{' '}
      <span className="text-xs">Checking website</span>
    </div>
  )
}

export const LargeLoader = () => {
  return (
    <div className="h-fit rounded-lg w-5/6">
      <div className="mx-24 my-10">
        <Loader2 className="mx-auto h-4 w-4 animate-spin" />
      </div>
    </div>
  )
}

export const UnderTitleLoader = () => {
  return (
    <div className="mt-24 w-full flex items-center justify-center">
      <Loader2 className="h-4 w-4 animate-spin mr-2" /> Loading...
    </div>
  )
}

export const PageLoader = () => {
  return (
    <div className="w-full h-screen flex-grow flex items-center justify-center  py-4 mx-2">
      <div
        key={'page-loader'}
        className="text-zinc-500 h-full w-full flex items-center justify-center my-4 bg-zinc-100 rounded-xl"
      >
        <Loader2 className="h-4 w-4 animate-spin mr-2" /> Loading...
      </div>
    </div>
  )
}

export const LandingPageLoader = ({ text }: { text: string }) => {
  return (
    <div className="mt-6 w-fit px-4 py-2 flex items-center justify-center bg-yellow-100 border border-yellow-300 text-yellow-800 border-1 rounded-lg">
      <Cog className="h-4 w-4 animate-spin mr-2" /> {text}
    </div>
  )
}
