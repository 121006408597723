import BottomNav from '@/components/navs/bottom-nav'
import { Button } from '@/components/ui/button'
import {
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  useOrganization,
  useOrganizationList,
  UserButton,
  useUser,
} from '@clerk/nextjs'
import Image from 'next/image'
import Link from 'next/link'
import React, { ReactNode, useEffect, useState } from 'react'

interface BenefitCardProps {
  title: string
  description: string
  CustomComponent?: ReactNode
  href: string
}

const DropdownLink: React.FC<BenefitCardProps> = ({
  title,
  description,
  CustomComponent,
  href,
}) => {
  return (
    <Link
      href={href}
      className="text-left w-full flex items-center text-zinc-500 hover:text-zinc-900"
    >
      {CustomComponent && <div className="mr-4">{CustomComponent}</div>}

      <div>
        <span className="font-semibold text-xs">{title}</span>
        {/* <br /> */}
        {/* <span className="font-medium text-xs">{description}</span> */}
      </div>
    </Link>
  )
}

export const CTAButton = ({
  analyticsKey,
  content,
  destination,
}: {
  destination: string
  content: string
  analyticsKey: string
}) => {
  return (
    <Link href={destination}>
      <Button
        variant={'link'}
        analyticsKey={analyticsKey}
        analyticsProperties={{
          section: 'public_site',
        }}
        className="m-0"
      >
        {content}
      </Button>
    </Link>
  )
}

const TopNav = () => {
  const { user, isLoaded } = useUser()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    if (!user) {
      return
    }
    user?.getOrganizationInvitations().then(() => {
      // console.log('res', res)
    })
  }, [isLoaded])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const orgList = useOrganizationList({
    userInvitations: {
      pageSize: 10,
    },
    userMemberships: {
      pageSize: 10,
    },
  })

  const { organization } = useOrganization()

  const isSubscribed = user?.publicMetadata.isSubscribed
  const onboarded = user?.publicMetadata.onboarded

  const linkDest = () => {
    if (!user) {
      return '/'
    } else {
      if (!isSubscribed) {
        return '/'
      } else {
        if (!onboarded) {
          return '/onboarding'
        } else {
          return '/dashboard'
        }
      }
    }
  }

  const renderOrganizationSwitcher = () => {
    if (
      (orgList.userInvitations.data?.length && orgList.userInvitations.data?.length > 0) ||
      (orgList.userMemberships.data?.length && orgList.userMemberships.data?.length > 0)
    ) {
      // Remove the defaultOpen prop to prevent it from opening by default
      return <OrganizationSwitcher />
    }
  }

  const renderActionButton = () => {
    if (organization?.id) {
      return (
        <CTAButton
          analyticsKey="top_nav_go_to_dashboard_with_org"
          content="Dashboard"
          destination="/dashboard"
        />
      )
    }

    if (user?.publicMetadata.isSubscribed) {
      if (!onboarded) {
        return (
          <CTAButton
            analyticsKey="top_nav_go_to_onboarding"
            content="Onboard"
            destination="/onboarding"
          />
        )
      }

      return (
        <CTAButton
          analyticsKey="top_nav_go_to_dashboard"
          content="Dashboard"
          destination="/dashboard"
        />
      )
    }

    return (
      <CTAButton analyticsKey="top_nav_go_to_pricing" content="Pricing" destination="/pricing" />
    )
  }

  return (
    <>
      {/* <div
        className={`border border-red-500 w-full fixed left-0 rounded-lg right-0 select-none py-4 z-50 items-center justify-between text-xs lg:flex grid grid-cols-2 md:mx-36 backdrop-blur-md bg-white/60 transition-all duration-300 ${
          isScrolled ? 'top-2' : 'top-9'
        }`}
      > */}

      <div className="lg:hidden fixed top-0 left-0 right-0 z-50">
        <div className="flex items-center justify-between px-6 py-4 bg-white/90 backdrop-blur-lg shadow-sm">
          <Link href={linkDest()} className="relative h-8 w-32">
            <Image
              className="object-contain"
              priority
              src="/files/logos/logo.webp"
              alt="Logo"
              fill
              sizes="(max-width: 128px) 100vw"
            />
          </Link>

          <div className="flex items-center space-x-4">
            <SignedOut>
              <Link
                href="/auth/signin"
                className="text-sm font-medium text-zinc-600 hover:text-zinc-900 transition-colors"
              >
                <span>Sign in</span>
              </Link>
              <Link
                href="/auth/signup"
                className="hidden md:block text-sm bg-primary font-bold text-white hover:bg-zinc-200 px-3 py-1.5 rounded-md transition-colors"
              >
                <span>Register</span>
              </Link>
            </SignedOut>
            <SignedIn>
              <Link
                href="/dashboard"
                className="text-sm md:bg-zinc-900 font-medium px-1 py-1.5 rounded-md transition-colors"
              >
                <span>View Dashboard</span>
              </Link>
              <UserButton />
            </SignedIn>
          </div>
        </div>
      </div>

      <div
        className={`w- mx-36 h-fit fixed left-0 rounded-lg right-0 select-none md:py-4 z-50 items-center justify-between text-xs lg:flex grid grid-cols-2 md:px-4 md:backdrop-blur-md md:bg-white/60 transition-all duration-300 ${
          isScrolled ? 'lg:top-0 bottom-0' : 'lg:top-8 bottom-0 px-12'
        }`}
      >
        <div className="hidden md:flex justify-center w-fit">
          <Link rel="canonical" href={linkDest()}>
            <Image
              className="object-scale-down h-10 w-fit"
              priority={true}
              src="/files/logos/logo.webp"
              alt="Logo"
              width={1000}
              height={1000}
            />
          </Link>
        </div>
        <div className="mr-auto hidden lg:block">
          <SignedOut>
            <div className="md:ml-6 flex items-center hidden md:flex gap-4">
              <Link href={linkDest()} className="relative h-8 w-32 lg:hidden">
                <Image
                  className="object-contain"
                  priority
                  src="/files/logos/logo.webp"
                  alt="Logo"
                  fill
                  sizes="(max-width: 128px) 100vw"
                />
              </Link>
              {/* <div className="group max-lg:border-b  max-lg:py-3 relative">
                <a
                  href="javascript:void(0)"
                  className="inline-flex items-center justify-center whitespace-nowrap rounded-lg text-xs font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 px-0 mx-0 m-0 text-zinc-800 underline-offset-4 hover:underline p-0  text-left"
                >
                  Tools <ChevronDown className="w-4 h-4 ml-1 hover" />
                </a>
                <ul className="rounded space-y-6 absolute top-5 max-lg:top-8 left-0 z-50 block space-y-2 shadow-lg bg-white max-h-0 overflow-hidden min-w-[250px] group-hover:opacity-100 group-hover:max-h-[900px] px-4 group-hover:pb-4 group-hover:pt-6 transition-all duration-500">
                  <DropdownLink
                    title="Price Analysis"
                    description="Expert AI Competitive Price Analysis"
                    href="/tools/competitive-price-analysis"
                  />

                  <DropdownLink
                    title="Product Comparison"
                    description="Accurate Product Comparison Tool"
                    href="/tools/product-comparison-tool"
                  />

                  <DropdownLink
                    title="Landing Page Comparison"
                    description="Comparison of Competitor Landing Pages"
                    href="/tools/competitor-landing-page-comparison"
                  />

                  <DropdownLink
                    title="SEO Monitoring"
                    description="The Ultimate SEO Monitoring Tool"
                    href="/tools/seo-monitor"
                  />

                  <DropdownLink
                    title="Email Monitoring"
                    description="Elevate Your Strategy with Email Monitoring"
                    href="/tools/competitor-email-monitoring"
                  />
                </ul>
              </div> */}
              {/* <Link href="https://blog.opinly.ai/">
                <Button
                  analyticsKey="blog"
                  variant={'link'}
                  analyticsProperties={{
                    section: 'public_site',
                  }}
                >
                  Blog
                </Button>
              </Link> */}
            </div>
          </SignedOut>
        </div>
        <div className="ml-auto mr-5">
          <SignedIn>
            {/* Desktop */}
            <div className="hidden sm:flex items-center space-x-4">
              <div className="flex items-center">
                {renderActionButton()}
                {renderOrganizationSwitcher()}
              </div>
              <UserButton />
            </div>
            {/* Mobile */}
            <div className="sm:hidden block">
              <BottomNav />
            </div>
          </SignedIn>

          <SignedOut>
            <div className="flex items-center hidden sm:block grid gap-4">
              <Link href={'/auth/signin'} className="mr-4">
                <Button
                  analyticsKey="top_nav_signin"
                  variant={'neutralLink'}
                  analyticsProperties={{
                    section: 'public_site',
                  }}
                >
                  Sign in
                </Button>
              </Link>
              <Link
                className="h-8 bg-zinc-900 font-semibold hover:bg-zinc-700 px-4 inline-flex items-center justify-center whitespace-nowrap rounded-lg text-xs font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-orange-500 text-primary-foreground hover:bg-orange-400 "
                href={'/auth/signup'}
              >
                GET STARTED
              </Link>
            </div>
          </SignedOut>
        </div>
      </div>
    </>
  )
}

export default TopNav
